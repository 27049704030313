import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const DEFAULT_TITLE = 'Expo Transporte';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta: { title: `${DEFAULT_TITLE} | Inicio` },
  },
  {
    path: '/smart-contracts',
    name: 'smart-contracts',
    component: () => import(/* webpackChunkName: "home" */ '../views/SmartContractsView.vue'),
    meta: { title: `${DEFAULT_TITLE} | Smart Contracts` },
  },
  {
    path: '/data-transactions',
    name: 'data-transactions',
    component: () => import(/* webpackChunkName: "home" */ '../views/DataTransactionsView.vue'),
    meta: { title: `${DEFAULT_TITLE} | Datos de Transacción` },
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: () => import(/* webpackChunkName: "home" */ '../views/TransactionView.vue'),
    meta: { title: `${DEFAULT_TITLE} | Transacción` },
  },
  {
    path: '/ticket',
    name: 'ticket',
    component: () => import(/* webpackChunkName: "home" */ '../views/TicketView.vue'),
    meta: { title: `${DEFAULT_TITLE} | Ticket` },
  },
  {
    path: '/FAB',
    name: 'FAB',
    component: () => import(/* webpackChunkName: "home" */ '../views/FABView.vue'),
    meta: { title: `${DEFAULT_TITLE} | FAB` },
  },
  {
    path: '/SobreProducto',
    name: 'SobreProducto',
    component: () => import(/* webpackChunkName: "home" */ '../views/SobreProducto.vue'),
    meta: { title: `${DEFAULT_TITLE} | Nuestro Producto` },
  },
  {
    path: '/TyC',
    name: 'tyc',
    component: () => import(/* webpackChunkName: "home" */ '../views/TyC.vue'),
    meta: { title: `${DEFAULT_TITLE} | TyC` },
  },
  {
    path: '/info',
    name: 'info',
    component: () => import(/* webpackChunkName: "home" */ '../views/Info.vue'),
    meta: { title: `${DEFAULT_TITLE} | Información` },
  },
];

window.popStateDetected = false;
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  const IsItABackButton = window.popStateDetected;
  window.popStateDetected = false;
  if (IsItABackButton && from.name === 'ticket') {
    next('/');
  }
  next();
});
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});
export default router;
